import * as React from "react";
import { useTranslation } from 'react-i18next';
import DialogContentText from '@material-ui/core/DialogContentText';
import Button from "@material-ui/core/Button";
import BaseDialog from "src/components/BaseDialog";
// import { usePackageUpgrade } from "app/features/packageUpgrade";

const isReactComponent = (opts) => {
   return typeof opts === "object"
      && opts.hasOwnProperty("message")
      && React.isValidElement(opts.message);
}

const alert = (opts, ref) => {

   const defaultTitle = "dialog.alert.title";
   const defaultOkButtonText = "dialog.alert.ok-button";

   let title, message, okButtonText;

   if (React.isValidElement(opts)) {
      title = ref.t(defaultTitle);
      okButtonText = ref.t(defaultOkButtonText);
      message = opts;
   }
   else if (isReactComponent(opts)) {
      title = ref.t(opts.title || defaultTitle);
      okButtonText = ref.t(opts.okButtonText || defaultOkButtonText);
      message = opts.message;
   }
   else {
      if (typeof opts === "string") {
         if (/\.\*$/.test(opts)) {
            const prefix = opts.replace(/\*$/, "");
            title = prefix + "title";
            message = ref.t(prefix + "message");
            okButtonText = prefix + "ok-button";
         }
         else {
            message = ref.t(opts);
         }
      }
      else if (typeof opts === "object" && opts.hasOwnProperty("message")) {
         title = opts.title;
         message = ref.t(opts.message);
         okButtonText = opts.okButtonText;
      }
      else {
         message = opts;
      }

      title = ref.t(title || defaultTitle);
      okButtonText = ref.t(okButtonText || defaultOkButtonText);
      message = (<DialogContentText>{"" + message}</DialogContentText>);
   }

   ref.setDialogState({
      title: title,
      content: message,
      action: (<>
         <Button variant="contained" color="primary" onClick={(e) => ref.handleClick(e, true)}>
            {okButtonText}
         </Button>
      </>),
      dialogProps: {
         onClose: (e, reason) => ref.handleClick(e, true)
      }
   });

   return ref.deferred();
}

const confirm = (opts, ref) => {

   const defaultTitle = "dialog.confirm.title";
   const defaultOkButtonText = "dialog.confirm.ok-button";
   const defaultCancelButtonText = "dialog.confirm.cancel-button";

   let title, message, okButtonText, cancelButtonText;

   if (React.isValidElement(opts)) {
      title = ref.t(defaultTitle);
      okButtonText = ref.t(defaultOkButtonText);
      cancelButtonText = ref.t(defaultCancelButtonText);
      message = opts;
   }
   else if (isReactComponent(opts)) {
      title = ref.t(opts.title || defaultTitle);
      okButtonText = ref.t(opts.okButtonText || defaultOkButtonText);
      cancelButtonText = ref.t(opts.cancelButtonText || defaultCancelButtonText);
      message = opts.message;
   }
   else {
      if (typeof opts === "string") {
         if (/\.\*$/.test(opts)) {
            const prefix = opts.replace(/\*$/, "");
            title = prefix + "title";
            message = ref.t(prefix + "message");
            okButtonText = prefix + "ok-button";
            cancelButtonText = prefix + "cancel-button";
         }
         else {
            message = ref.t(opts);
         }
      }
      else if (typeof opts === "object" && opts.hasOwnProperty("message")) {
         title = opts.title;
         message = ref.t(opts.message);
         okButtonText = opts.okButtonText;
         cancelButtonText = opts.cancelButtonText;
      }
      else {
         message = opts;
      }

      title = ref.t(title || defaultTitle);
      okButtonText = ref.t(okButtonText || defaultOkButtonText);
      cancelButtonText = ref.t(cancelButtonText || defaultCancelButtonText);
      message = (<DialogContentText>{"" + message}</DialogContentText>);
   }

   ref.setDialogState({
      title: title,
      content: message,
      action: (<>
         <Button variant="outlined" color="primary" onClick={(e) => ref.handleClick(e, false)} autoFocus>
            {cancelButtonText}
         </Button>
         <Button variant="contained" color="primary" onClick={(e) => ref.handleClick(e, true)}>
            {okButtonText}
         </Button>
      </>),
      dialogProps: {
         onClose: (e, reason) => ref.handleClick(e, false)
      }
   });

   return ref.deferred();
}

const error = (opts, ref) => {

   const defaultTitle = "dialog.error.title";
   const defaultOkButtonText = "dialog.error.ok-button";

   if (opts instanceof Error) {
      let title, message, okButtonText;

      if (opts.response) {
         const { headers, status, statusText, data } = opts.response;

         const messageContent = (
            <DialogContentText>{data.code ? ref.t([data.code, data.message]) : statusText}</DialogContentText>
         );

         const traceContent = (
            headers["__trace_id"] && <DialogContentText>CODE: {headers["__trace_id"]}</DialogContentText>
         );

         message = (<>
            {messageContent}
            {traceContent}
         </>);
      }
      else {
         message = (<DialogContentText>{"" + opts}</DialogContentText>);
      }

      title = ref.t(defaultTitle);
      okButtonText = ref.t(defaultOkButtonText);

      ref.setDialogState({
         title: title,
         content: message,
         action: (<>
            <Button variant="contained" color="primary" onClick={(e) => ref.handleClick(e, true)}>
               {okButtonText}
            </Button>
         </>),
         dialogProps: {
            onClose: (e, reason) => ref.handleClick(e, true)
         }
      });
   }
   else {
      let title, message, okButtonText;

      if (React.isValidElement(opts)) {
         title = ref.t(defaultTitle);
         okButtonText = ref.t(defaultOkButtonText);
         message = opts;
      }
      else if (isReactComponent(opts)) {
         title = ref.t(opts.title || defaultTitle);
         okButtonText = ref.t(opts.okButtonText || defaultOkButtonText);
         message = opts.message;
      }
      else {
         if (typeof opts === "string") {
            if (/\.\*$/.test(opts)) {
               const prefix = opts.replace(/\*$/, "");
               title = prefix + "title";
               message = ref.t(prefix + "message");
               okButtonText = prefix + "ok-button";
            }
            else {
               message = ref.t(opts);
            }
         }
         else if (typeof opts === "object" && opts.hasOwnProperty("message")) {
            title = opts.title;
            message = ref.t(opts.message);
            okButtonText = opts.okButtonText;
         }
         else {
            message = opts;
         }

         title = ref.t(title || defaultTitle);
         okButtonText = ref.t(okButtonText || defaultOkButtonText);
         message = (<DialogContentText>{"" + message}</DialogContentText>);
      }

      ref.setDialogState({
         title: title,
         content: message,
         action: (<>
            <Button variant="contained" color="primary" onClick={(e) => ref.handleClick(e, true)}>
               {okButtonText}
            </Button>
         </>),
         dialogProps: {
            onClose: (e, reason) => ref.handleClick(e, true)
         }
      });
   }

   return ref.deferred();
}

// const upgradePackage = (opts, ref) => {
//    // const defaultCancelButtonText = "dialog.confirm.cancel-button";

//    const { UpgradeTitle, UpgradeButton, UpgradeContent } = usePackageUpgrade()
//    const primaryAdminId = opts.primaryAdminId || '';
//    const feature = opts.feature
//    const cancelButtonText = 'cancel';

//    ref.setDialogState({
//       title: <UpgradeTitle />,
//       content: <UpgradeContent featureType={feature}  />,
//       action: (<>
//          <Button variant="outlined" color="primary" onClick={(e) => ref.handleClick(e, false)} autoFocus>
//             {cancelButtonText}
//          </Button>
//          <UpgradeButton btnText={"continue checkout"} primaryAdminId={primaryAdminId} close={(e, reason) => ref.handleClick(e, false)} />
//       </>),
//       dialogProps: {
//          onClose: (e, reason) => ref.handleClick(e, true),
//          fullWidth: true,
//          maxWidth: "md",
//       }
//    });
//    return ref.deferred();
// }

const DialogServiceContext = React.createContext({
   alert: () => Promise.resolve(void 0),
   confirm: () => Promise.resolve(void 0),
   error: () => Promise.resolve(void 0),
   // upgradePackage: () => Promise.resolve(void 0),
});

export const useDialog = () => React.useContext(DialogServiceContext);

// export const withDialog = (component) => {

//    const withDialogHOC = props => {

//       const additionalProps = {
//          dialog: useDialog(),
//       };

//       return React.createElement(component, { ...props, ...additionalProps })
//    };

//    withDialogHOC.displayName = "withDialog";

//    return withDialogHOC;
// }

export const DialogService = ({ children }) => {

   const [t] = useTranslation();

   const [ dialogState,setDialogState] = React.useState(null);

   const awaitingPromiseRef = React.useRef({
      resolve: () => (void 0),
      reject: () => (void 0)
   });

   const deferred = () => new Promise((resolve, reject) => {

      awaitingPromiseRef.current = { resolve, reject };
   });

   const handleClick = (e, value) => {

      if (awaitingPromiseRef.current) {
         awaitingPromiseRef.current.resolve(value);
      }

      setDialogState(null);
   };

   const ref = React.useMemo(() => ({
      setDialogState,
      handleClick,
      deferred,
      t,
   }), []);

   const dialog = React.useMemo(() => ({
      // ALERT
      // - { title, message, okButtonText } #message -> string | components
      // - message
      // - component
      alert: (opts) => alert(opts, ref),
      // CONFIRM
      // - { title, message, okButtonText, cancelButtonText } #message -> string | components
      // - message
      // - component
      confirm: (opts) => confirm(opts, ref),
      // ERROR
      // - { title, message, okButtonText } #message -> string | components
      // - message
      // - component
      // - http error
      // - error
      error: (opts) => error(opts, ref),
   //    //UPGRADE_PACKAGE
   //    // - opts =  { message, primaryAdminId, feature } all shuld be string
   //    upgradePackage: (opts) => upgradePackage(opts, ref)
   }), [ref])

   return (<>
      <DialogServiceContext.Provider
         value={dialog}
         children={children}
      />

      <BaseDialog
         open={Boolean(dialogState)}
         {...dialogState}
      />
   </>);
};
