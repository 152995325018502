import i18n from 'i18next';
// import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import moment from 'moment';
import translationEN from './assets/locales/en/translation.json';
import translationTH from './assets/locales/th/translation.json';

moment.locale(localStorage.getItem("i18nextLng") || "th");

const resources = {
  en: {
    translation: translationEN
  },
  th: {
    translation: translationTH
  }
};

const options = {
  // order and from where user language should be detected
  order: ['querystring','localStorage'],

  lookupQuerystring: 'lng',
  lookupLocalStorage: 'i18nextLng',
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    detection: options,
    //# language to use (overrides language detection).
    //# If set to 'cimode' the output text will be the key.
    // lng: "th",
    //# use th if detected lng is not available
    fallbackLng: "th",
    // debug: true,
    supportedLngs: ['th', 'en'],
    //# char to separate keys
    //# (if working with a flat json, it's recommended to set keySeparator to false)
    // keySeparator: false,
    //# logs info level to console output. Helps finding issues with loading not working.
    // debug: true,
    //# char to split namespace from key `:`
    nsSeparator: false,
    //keySeparator: false,

    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      useSuspense: true,

    }
  });

  i18n.on('languageChanged', function(lng) {
    moment.locale(lng);
  })
export default i18n;