import axios from "axios";
import { getConfiguration } from "../config/server";
const instance = axios.create({
	baseURL: getConfiguration().apiUrl,
	headers: {
		"X-localization": localStorage.getItem("i18nextLng") || "en",
	},
});

// instance.interceptors.request.use(
// 	async (config) => {
// 		config.headers["X-localization"] =
// 			localStorage.getItem("i18nextLng") || "en";
// 		return config;
// 	},
// 	(error) => {
// 		return Promise.reject(error);
// 	}
// );

instance.interceptors.request.use(
	async (config) => {
		// const jwtToken = localStorage.getItem("token_device");
		// if (jwtToken != null) {
			// config.headers["Authorization"] = `Bearer ${jwtToken}`;
			config.headers["X-localization"] =
				localStorage.getItem("i18nextLng") || "en";
			// config.transformResponse = data => JSONBigInt.parse(data);
		// }

		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);

export default instance;
