import {
  AUTHENTICATION_SUCCESS,
  AUTHENTICATION_FAILURE,
} from "../actions/AuthAction";

const initialState = {
  user: null,
};

export default function authenReducer(state = initialState, action) {
  switch (action.type) {
    case AUTHENTICATION_SUCCESS:
      console.log("action.payload : ", action.payload);
      return {
        user: action.payload.auth,
      };

    case AUTHENTICATION_FAILURE:
      return {
        user: null,
      };
    default:
      return state;
  }
}
