import { axiosTokenDevice } from "src/common/AxiosTokenDevice";
import createPusher from "src/common/createPusher";
import { useDialog } from "src/common/dialog";
import clientError from "src/common/handleClientError";
import { getConfigurationApiPusher } from "src/config/AuthPusherConfig";
import axiosInstance from "../../common/axiosInstance";
import { forceClearCache } from "./ChannelAction";

const startPusher = (config = {}) => {
   return async (dispatch) => {
      const { eventName, ...restConfig } = config;
      const { pusher, channel } = await createPusher(restConfig);
      channel.bind(eventName, async (data) => {
         await dispatch(pusherController({ ...data, pusher, channel }));
      });
   };
};

export function startDevicePusher() {
   return async (dispatch) => {
      const tokenDevice = localStorage.getItem("token_device");
      dispatch(
         startPusher({
            channelName: "private-handle-for-device",
            eventName: "event-handle-device",
            authEndpoint: getConfigurationApiPusher(),
            auth: {
               headers: { Authorization: `Bearer ${tokenDevice}` },
            },
         })
      );
   };
}

export function pusherController({ action, pusher, channel, ...data }) {
   return async (dispatch, getState) => {
      const user = JSON.parse(localStorage.getItem("user"));
      const deviceId = user?.device?.id;
      if (data?.device_id === deviceId) {
         //! Done!!!!!!!!!
         if (action === "event-delete-standy") {
            await dispatch(getDefaultScheduleFailure());
            await dispatch(getSchedulesFailure());
            localStorage.removeItem("user");
            // pusher.unsubscribe("private-handle-for-device-" + deviceId);
            console.log("---------------------1");
         }
         // ! Done!!!!!
         if (action === "event-default-job") {
            await dispatch(getNewDefaultContent(data, deviceId));
            console.log("---------------------2");
         }
         //! Done!!!!!!!
         // updated Switcher job Schedules.
         if (action === "event-update-device-job") {
            window.location.reload();
            await dispatch(getSchedulesResponse(deviceId));
            await dispatch(getNewInfoDevice(deviceId));
            console.log("---------------------3");
         }
         //! Done!!!!!!
         // TODO Double Pusher Log?
         if (action === "event-update-job-contents") {
            if (data.content_id) {
               await dispatch(getNewContent(data.content_id));
               console.log("---------------------4");
            } else {
               const schedules = getState().pusher.schedules;
               if (!schedules || schedules.length === 0) {
                  await dispatch(getSchedulesResponse(deviceId));
                  console.log("---------------------5");
               } else {
                  await dispatch(getNewJobContent(data.message));
                  console.log("---------------------6");
               }
            }
         }
         // ! Done!!!!!!!
         if (action === "event-update-survey-content") {
            const schedules = getState().pusher.schedules;
            if (!data?.message) {
               await dispatch(findNewSurveyContent(schedules, data.survey_id));
               console.log("---------------------7");
            }
         }
         if (action === "event-force-clear-cache") {
            await dispatch(forceClearCache());
            console.log("---------------------8");
         }
         if (action === "event-new-day") {
            await dispatch(getSchedulesResponse(deviceId));
            console.log("---------------------9");
         }
         if (action === "event-device-force-logout") {
            console.log("event-device-force-logout");
            await channel.unsubscribe(channel.name);
            localStorage.removeItem("user");
            localStorage.removeItem("token_device");
            localStorage.removeItem("device_accessed_at");
            localStorage.removeItem("current_device_id");
            window.location.reload();
            console.log("---------------------10");
         }
         if (action === "event-unsubscribe") {
            await channel.unsubscribe(channel.name);
            console.log("---------------------11");
         }
      }
   };
}

//////////===================== ////
// export function getNewDay(deviceID) {
// 	return async (dispatch) => {
// 		const { message } = await createPusher({
// 			channelName: "channel-all-device",
// 			eventName: "event-new-day",
// 		});
// 		if (message) {
// 			await dispatch(getSchedulesResponse(deviceID));
// 		}
// 	};
// }

// api
export function getNewContent(content_id) {
   return async (dispatch, getState) => {
      const linkGetNewContent = `/api/get-content/${content_id}`;
      try {
         const response = await axiosInstance.get(linkGetNewContent);
         const content = response?.data?.content;
         const currentContent = getState().pusher?.schedules;
         const findIndex = currentContent?.findIndex((con) => con.content.id === content_id);
         const findSurveyContent = currentContent?.find((con) => con.content.id === content_id);
         console.log("findSurveyContentfindSurveyContent : ", findSurveyContent);
         const createContent = {
            ...currentContent[findIndex],
            content: content?.survey_id
               ? {
                    ...content,
                    vs: findSurveyContent?.content?.vs !== undefined ? findSurveyContent?.content?.vs + 1 : 0,
                 }
               : content,
         };
         console.log("createContent createContent : ", createContent);
         const newContent = [...currentContent?.slice(0, findIndex), createContent, ...currentContent?.slice(findIndex + 1)];
         await dispatch(getSchedulesSuccess(newContent));
         console.log("new Content");
      } catch (error) {
         console.log("error getNewContent : ", error);
         await dispatch(getSchedulesFailure());
         clientError(error);
      }
   };
}

// api
export function getNewJobContent(jobID) {
   return async (dispatch, getState) => {
      const linkGetNewContent = `/api/get-job/${jobID}`;
      try {
         const res = await axiosInstance.get(linkGetNewContent);
         const newJob = res?.data?.schedule;
         const currentSchedules = getState().pusher?.schedules;
         const checkUpdateSchedules = currentSchedules?.length === newJob?.length;
         let newCurrentSchedules = null;
         const findNewJob = newJob?.find((newJob) => !currentSchedules?.some((job) => job.id === newJob.id));
         const deleteJob = currentSchedules?.find((sch) => !newJob?.some((job) => job.id === sch.id));
         if (checkUpdateSchedules) {
            console.log("1:::");
            console.log("checkUpdateSchedules : ", checkUpdateSchedules);
            const findCurrentUpdate = newJob?.find((job) => currentSchedules?.some((sch) => sch.updated_at !== job.updated_at && sch.id === job.id));
            const currentIndex = currentSchedules?.findIndex((current) => current.id === findCurrentUpdate?.id);
            console.log("findCurrentUpdate : ", findCurrentUpdate);
            newCurrentSchedules = [...currentSchedules?.slice(0, currentIndex), findCurrentUpdate, ...currentSchedules?.slice(currentIndex + 1)];
         } else if (findNewJob) {
            console.log("2:::");
            newCurrentSchedules = [...currentSchedules, findNewJob];
         } else if (deleteJob) {
            console.log("3:::");
            const findDelete = currentSchedules?.filter((sch) => sch.id !== deleteJob?.id);
            newCurrentSchedules = findDelete;
         }
         console.log("newCurrentSchedules : ", newCurrentSchedules);
         await dispatch(getSchedulesSuccess(newCurrentSchedules));
      } catch (error) {
         console.log("error getNewJob : ", error);
         await dispatch(getSchedulesFailure());
         clientError(error);
      }
   };
}

//done
// export function createJobPusher(schedules) {
//    return async (dispatch) => {
//       const pusher = new Pusher("ccb0403ea24a86f5024a", {
//          cluster: "ap1",
//       });
//       const local = JSON.parse(localStorage.getItem("user"));
//       const channelJobs = pusher.subscribe("my-channel-" + local?.device?.team_device_job_id);
//       console.log("channelJobs :::: ", channelJobs);
//       await dispatch(setPusherChannel(channelJobs?.name));
//       channelJobs.bind("event-update-job-contents", (data) => {
//          if (!schedules && schedules.length < 1) {
//             dispatch(getSchedulesResponse(local?.device?.id));
//          } else {
//             dispatch(getNewJobContent(data.message));
//          }
//       });
//       schedules?.length > 1 &&
//          schedules?.forEach(async (schedule) => {
//             const channelContent = pusher.subscribe("my-channel-" + schedule?.content?.id);
//             await dispatch(setPusherChannel(channelContent?.name));
//             channelContent.bind("event-update-job-contents", (data) => {
//                dispatch(getNewContent(data.content_id));
//             });
//          });
//    };
// }

// nothing
// export function pusherBindMessage(deviceID) {
// 	return async (dispatch) => {
// 		if (!deviceID) {
// 			return;
// 		}
// 		const pusher = new Pusher("ccb0403ea24a86f5024a", {
// 			cluster: "ap1",
// 		});
// 		const channel = pusher.subscribe("my-channel-" + deviceID);
// 		await dispatch(setPusherChannel(channel?.name));
// 		console.log("channel : ", channel);
// 		channel.bind("my-event", (data) => {
// 			dispatch(getSchedulesResponse(data.message));
// 		});
// 	};
// }

// api
export function getSchedulesResponse(deviceId) {
   return async (dispatch) => {
      const local = JSON.parse(localStorage.getItem("user"));
      if (deviceId !== local.device.id) return;
      const linkGetContents = `/api/get-jobs/${deviceId}`;
      try {
         const response = await axiosTokenDevice.get(linkGetContents);
         const res = response?.data?.schedule;
         const defaultRes = response?.data?.default_schedule;
         console.log("getSchedulesResponse : ", response.data);
         const schedules = res.map((schedule) => ({
            ...schedule,
            content: {
               ...schedule.content,
               ...(schedule.content?.survey_id !== null ? { vs: 0 } : {}),
            },
         }));
         await dispatch(getSchedulesSuccess(schedules));
         await dispatch(getDefaultScheduleSuccess(defaultRes));
      } catch (error) {
         await dispatch(getSchedulesFailure());
         await dispatch(getDefaultScheduleFailure());
         clientError(error);
         throw error;
      }
   };
}

// done
// export function pusherNewUpdateSurvey(schedule) {
//    return async (dispatch) => {
//       const pusher = new Pusher("ccb0403ea24a86f5024a", {
//          cluster: "ap1",
//       });
//       schedule?.forEach(async (sch) => {
//          if (sch?.content?.survey_id) {
//             const channel = pusher.subscribe("my-survey-" + sch.content.survey_id);
//             await dispatch(setPusherChannel(channel?.name));
//             console.log("pusherNewUpdateSurvey : ", channel);
//             channel.bind("event-update-survey-content", async (data) => {
//                console.log("event-update-survey-content : ", data);
//                if (!data?.message) {
//                   await dispatch(findNewSurveyContent(schedule, data.survey_id));
//                } else {
//                   pusher.unsubscribe("my-survey-" + sch.content.survey_id);
//                }
//             });
//          }
//       });
//    };
// }

export function findNewSurveyContent(schedules, surveyID) {
   return async (dispatch) => {
      if (schedules?.length > 0 && surveyID) {
         const findContent = schedules?.find((sch) => sch.content.survey_id === surveyID);
         const contentUpdateID = findContent?.content?.id;
         if (contentUpdateID) {
            await dispatch(getNewContent(contentUpdateID));
         }
      }
   };
}

// done
// export function getDeleteDevice(deviceId) {
//    return async (dispatch) => {
//       const pusher = new Pusher("ccb0403ea24a86f5024a", {
//          cluster: "ap1",
//       });
//       const channel = pusher.subscribe("my-standy-" + deviceId);
//       await dispatch(setPusherChannel(channel?.name));
//       console.log("channel : ", channel);
//       channel.bind("event-delete-standy", async (data) => {
//          console.log("getDeleteDevice : ", data);
//          pusher.unsubscribe("my-standy-" + deviceId);
//          pusher.unsubscribe("my-channel-" + deviceId);
//          await dispatch(getSchedulesFailure());
//          localStorage.removeItem("user");
//       });
//    };
// }

// done
// export function pusherNewUpdateDefaultContent(jobID, deviceID) {
//    return async (dispatch) => {
//       const pusher = new Pusher("ccb0403ea24a86f5024a", {
//          cluster: "ap1",
//       });
//       const channel = pusher.subscribe("my-default-job-" + jobID);
//       await dispatch(setPusherChannel(channel?.name));
//       channel.bind("event-default-job", async (data) => {
//          await dispatch(getNewDefaultContent(data, deviceID));
//       });
//    };
// }

// api
export function getNewDefaultContent(pusher, deviceID) {
   return async (dispatch) => {
      // const conPusher = new Pusher("ccb0403ea24a86f5024a", {
      //    cluster: "ap1",
      // });
      const linkGetSchedules = `/api/get-jobs/${deviceID}`;
      try {
         const response = await axiosInstance.get(linkGetSchedules);
         const defaultContent = response?.data?.default_schedule;
         // not neccecary to unSub
         // if (defaultContent?.length <= 0 && pusher?.message === "deleted") {
         //    conPusher.unsubscribe("my-default-job-" + pusher?.job_id);
         // }
         await dispatch(getDefaultScheduleSuccess(defaultContent));
      } catch (error) {
         clientError(error);
         console.log("error getNewDefaultContent : ", error);
         await dispatch(getDefaultScheduleFailure());
      }
   };
}

export function getUpdatedOnlineDevice(deviceId) {
   return async (dispatch) => {
      const currentDevice = await JSON.parse(localStorage.getItem("current_device_id"));
      const version = await JSON.parse(localStorage.getItem("version"));
      const linkGetCheckOnlineDevice = `/api/device/updated-online`;
      try {
         const check = await axiosTokenDevice.get(linkGetCheckOnlineDevice, {
            params: {
               device_id: deviceId,
               current_device: currentDevice,
               version: version || 0,
            },
         });
         console.log("getCheckOnlineDevice ===== ", check.data);
      } catch (error) {
         clientError(error);
         console.log("error getCheckOnlineDevice : ", error);
      }
   };
}

export function getNewInfoDevice(deviceId) {
   return async (dispatch) => {
      const linkGetInfo = `/api/get-info-device/${deviceId}`;
      try {
         const res = await axiosTokenDevice.get(linkGetInfo);
         await localStorage.setItem("user", JSON.stringify(res?.data));
      } catch (error) {
         clientError(error);
         console.log("error getNewInfoDevice : ", error);
      }
   };
}

// done
// export function pusherNewUpdateDeviceJob(deviceID) {
//    return async (dispatch) => {
//       const pusher = new Pusher("ccb0403ea24a86f5024a", {
//          cluster: "ap1",
//       });
//       const channel = pusher.subscribe("my-device-job-" + deviceID);
//       console.log("pusherNewUpdateDeviceJob ::::", channel);
//       await dispatch(setPusherChannel(channel?.name));
//       channel.bind("event-update-device-job", async (data) => {
//          if (data?.device_id === deviceID) {
//             window.location.reload();
//             await dispatch(getSchedulesResponse(deviceID));
//          }
//       });
//    };
// }

export const GET_MESSAGE_SUCCESS = "GET_MESSAGE_SUCCESS";
export const GET_SCHEDULES_SUCCESS = "GET_SCHEDULES_SUCCESS";
export const GET_SCHEDULES_FAILURE = "GET_SCHEDULES_FAILURE";
export const GET_DEFAULT_SCHEDULES_SUCCESS = "GET_DEFAULT_SCHEDULES_SUCCESS";
export const GET_DEFAULT_SCHEDULES_FAILURE = "GET_DEFAULT_SCHEDULES_FAILURE";

export const bindMessageSuccess = (msg) => ({
   type: GET_MESSAGE_SUCCESS,
   payload: { msg },
});

export const getSchedulesSuccess = (schedule) => ({
   type: GET_SCHEDULES_SUCCESS,
   payload: { schedule },
});

export const getSchedulesFailure = () => ({
   type: GET_SCHEDULES_FAILURE,
});

export const getDefaultScheduleSuccess = (defaultSchedule) => ({
   type: GET_DEFAULT_SCHEDULES_SUCCESS,
   payload: { defaultSchedule },
});

export const getDefaultScheduleFailure = () => ({
   type: GET_DEFAULT_SCHEDULES_FAILURE,
});
