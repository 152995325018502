import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "styled-components";
import theme from "src/common/theme";
import SurveyReactConfig from "./config/surveyReact/surveyConfig";
import Routes from "./Routes";
import moment from "moment-timezone";
import { Provider } from "react-redux";
import store from "./redux/store";
import { DialogService } from "src/common/dialog";
import ErrorBoundary from "./pages/errorPage/ErrorBoundary";

function App() {
	moment.tz.setDefault("Asia/Bangkok");
	return (
		<div className="App">
			<ErrorBoundary>
				<Provider store={store}>
					<MuiThemeProvider theme={theme}>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<SurveyReactConfig />
							<DialogService>
								<Routes />
							</DialogService>
						</ThemeProvider>
					</MuiThemeProvider>
				</Provider>
			</ErrorBoundary>
		</div>
	);
}

export default App;
