import { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ComponentLoading from "src/components/ComponentLoading3";

// import { useTranslation } from "react-i18next";

const Job = lazy(() => import("./pages/Job"));
const Home = lazy(() => import("./pages/Home"));
const Standy = lazy(() => import("./pages/Standy"));
const ObserverLogin = lazy(() => import("./pages/ObserverLogin"));
const SurveyRespondents = lazy(() =>
   import("./pages/surveyRespondents/SurveyRespondents")
);
const Observer = lazy(() => import("./pages/Observer"));
const NotificationsObserver = lazy(() =>
   import("./pages/observers/AllNotificationObserver")
);
const DeviceObservers = lazy(() =>
   import("./components/observers/DeviceObservers")
);
const DeviceNotificationObserver = lazy(() =>
   import("./pages/observers/DeviceNotificationsObserver")
);

const Routers = () => {
   return (
      <Routes>
         <Route
            path="/"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <Home />
               </Suspense>
            }
         />
         <Route
            path="job"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <Job />
               </Suspense>
            }
         />
         <Route
            path="standy"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <Standy />
               </Suspense>
            }
         />
         <Route
            path="d/:code"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <SurveyRespondents />
               </Suspense>
            }
         />

         <Route
            path="oblogin"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <ObserverLogin />
               </Suspense>
            }
         />
         <Route
            path="observer"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <Observer />
               </Suspense>
            }
         />
         <Route
            path="observer/notification"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <NotificationsObserver />
               </Suspense>
            }
         />
         <Route
            path="observer/device"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <DeviceObservers />
               </Suspense>
            }
         />
         <Route
            path="observer/device-notification"
            element={
               <Suspense fallback={<ComponentLoading />}>
                  <DeviceNotificationObserver />
               </Suspense>
            }
         />
      </Routes>
   );
};

export default Routers;
