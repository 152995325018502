import * as React from "react";
import { SurveyQuestionElementBase } from "../../react/reactquestion_element";
import { SurveyQuestionCommentItem } from "../../react/reactquestion_comment";
import { QuestionEmotionModel } from "../question_emotion";
import { ItemValue } from "survey-core";
import { ReactQuestionFactory } from "../../react/reactquestion_factory";

export class SurveyQuestionEmotion extends SurveyQuestionElementBase {
    constructor(props: any) {
        super(props);
        this.handleOnChange = this.handleOnChange.bind(this);
    }
    protected get question(): QuestionEmotionModel {
        return this.questionBase as QuestionEmotionModel;
    }
    handleOnChange(event: any) {
        this.question.value = event.target.value;
        this.setState({ value: this.question.value });
    }
    protected renderElement(): JSX.Element {
        var cssClasses = this.question.cssClasses;
        var values = [];
        for (var i = 0; i < this.question.visibleRateValues.length; i++) {
            values.push(
                this.renderItem(
                    "value" + i,
                    this.question.visibleRateValues[i],
                    i,
                    cssClasses
                )
            );
        }
        var comment = this.question.hasOther ? this.renderOther(cssClasses) : null;
        return (
            <div className={cssClasses.root} ref={(div) => (this.control = div)}>
                <fieldset role="radiogroup">
                    <legend aria-label={this.question.locTitle.renderedHtml} />
                    <div className="sv_q_emotion_items_root">
                        {values}
                    </div>
                </fieldset>
                {comment}
            </div>
        );
    }
    protected renderItem(
        key: string,
        item: ItemValue,
        index: number,
        cssClasses: any
    ): JSX.Element {
        var isChecked = this.question.value == item.value;
        var className = cssClasses.item;
        var classLabel = cssClasses.label;
        if (isChecked) className += " " + cssClasses.selected;
        if (this.isDisplayMode) className += " " + cssClasses.disabled;

        var itemText = this.renderLocString(item.locText);
        var style: any = { objectFit: this.question.imageFit };
        var imageControl = <img
            className={cssClasses.image}
            src={item["imageLink"]}
            width={
                this.question.imageWidth
                    ? this.question.imageWidth + "px"
                    : undefined
            }
            height={
                this.question.imageHeight
                    ? this.question.imageHeight + "px"
                    : undefined
            }
            alt={item.text || item.value}
            style={style}
        />
        var textLabel = this.question.showLabel ? <span className={cssClasses.itemText}>{itemText}</span> : null;

        return (
            <div key={key} className={className}>
                <label key={key} className={classLabel}>
                    <input
                        type="radio"
                        className="sv-visuallyhidden"
                        name={this.question.name}
                        id={this.question.inputId + "_" + index}
                        value={item.value}
                        disabled={this.isDisplayMode}
                        checked={this.question.value == item.value}
                        onChange={this.handleOnChange}
                        aria-required={this.question.isRequired}
                        aria-label={this.question.locTitle.renderedHtml}
                        aria-invalid={this.question.errors.length > 0}
                        aria-describedby={
                            this.question.errors.length > 0
                                ? this.question.id + "_errors"
                                : null
                        }
                    />
                    <div>
                        {imageControl}
                        {textLabel}
                    </div>
                </label>
            </div>
        );
    }
    protected renderOther(cssClasses: any): JSX.Element {
        return (
            <div className={cssClasses.other}>
                <SurveyQuestionCommentItem
                    question={this.question}
                    cssClasses={cssClasses}
                    isDisplayMode={this.isDisplayMode}
                />
            </div>
        );
    }
}
ReactQuestionFactory.Instance.registerQuestion("emotion", (props) => {
    return React.createElement(SurveyQuestionEmotion, props);
});
