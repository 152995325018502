import React, { Component } from "react";

// Mui
import styled from 'styled-components';
import { withStyles } from "@material-ui/core/styles";
// Dialog
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from "@material-ui/core/DialogActions";
import Divider from "@material-ui/core/Divider";
// Icon
import IconClose from "@material-ui/icons/Close";

const Dialogstyled = styled(Dialog)`
  ${({ theme, dialogpadding }) => `
    
    .MuiPaper-root {

      min-width: 250px;
      padding: ${dialogpadding};
    }

    .MuiDialogTitle-root {

      padding-left: 0;
      padding-right: 0;

      .MuiTypography-root {

        font-size: 18px;
      }
    }

    .MuiDialogContent-root {

      padding-left: 0;
      padding-right: 0;

      .MuiTypography-root {

      }
    }

    .MuiDialogContent-root:first-child {
        padding-top: 10px;
    }

    .MuiDialogActions-root {
      
      padding-left: 0;
      padding-right: 0;

      justify-content: center;

      .MuiButton-root {

        min-width: 120px;
      }
    }
  `}
`;

const BaseDialog = ({
  open,
  title,
  content,
  action,
  dialogProps,
}) => {
    const {dialogpadding = null , ...restDialogProps } = dialogProps || {}
    return (
      <Dialogstyled 
        // disableBackdropClick={true}
        open={open}
        dialogpadding={dialogpadding ?? '30px 50px'}
        {...restDialogProps} 
        onClose={undefined}
      >
        {title &&
          <DialogTitle>
            {title}
          </DialogTitle>
        }

        <DialogContent>
          {content}
        </DialogContent>

        <DialogActions>
          {action}
        </DialogActions>
      </Dialogstyled>
    );
}

export default BaseDialog;
