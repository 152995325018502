import axiosInstance from "../../common/axiosInstance";
import createPusher from "src/common/createPusher";
import { forceClearCache } from "./ChannelAction";
import { axiosTokenObserver } from "src/common/AxiosTokenObserver";
import { getConfigurationApiPusher } from "src/config/AuthPusherConfig";
import clientError from "src/common/handleClientError";
import moment from "moment";

const startPusher = (config = {}) => {
   return async (dispatch) => {
      const { eventName, ...restConfig } = config;
      const { pusher, channel } = await createPusher(restConfig);
      channel.bind(eventName, async (data) => {
         console.log("pusher Observer event action start :::", data);
         console.log("pusher Observer event action start :::");
         await dispatch(pusherController({ ...data, pusher, channel })); // channelKey == deviceId
      });
   };
};

export function startObserverPusher(observerId) {
   const token = localStorage.getItem("token_observer");
   return async (dispatch) => {
      await dispatch(
         startPusher({
            channelName: "private-handle-for-observer",
            eventName: "event-handle-observer",
            authEndpoint: getConfigurationApiPusher(),
            auth: {
               headers: {
                  Authorization: `Bearer ${token}`,
               },
            },
         })
      );
   };
}

export const pusherController = ({ action, pusher, channel, ...data }) => {
   return async (dispatch) => {
      const observer = JSON.parse(localStorage.getItem("observer"));
      const observerId = observer?.id;
      // TODO fixed
      if (observerId === data?.observer_id) {
         if (action === "event-update-observer") {
            if (data?.message !== "deleted") {
               await dispatch(getNewObserverInfo(observerId));
               await dispatch(getObserverResponse(data));
               console.log("1========");
            } else {
               await dispatch(observerLogout(observerId));
               await dispatch(getObserverResponse(data.message));
               console.log("2========");
            }
         }
         if (action === "event-update-device-observer") {
            await dispatch(getInfoObserver(observerId));
            await dispatch(getObserverResponse(data));
            console.log("3========");
         }
         if (action === "observer-event") {
            await dispatch(getObserverResponse(data));
            await dispatch(getAllObserverNotification(observerId));
            console.log("4========");
         }
         if (action === "event-updated-authen-device") {
            await dispatch(getInfoObserver(observerId));
            console.log("5========");
         }
         if (action === "event-force-clear-cache") {
            await dispatch(forceClearCache());
            console.log("6========");
         }
         if (action === "event-observer-force-logout") {
            await channel.unsubscribe(channel.name);
            localStorage.removeItem("observer");
            localStorage.removeItem("token_observer");
            localStorage.removeItem("time_interval_observer");
            localStorage.removeItem("observer_accessed_at");
            localStorage.removeItem("version");
            window.location.reload();
            console.log("7========");
         }
         if (action === "event-unsubscribe") {
            await channel.unsubscribe(channel.name);
            console.log("8========");
         }
      }
   };
};

//* API
export function getNewObserverInfo(observerID) {
   return async (dispatch) => {
      const linkGetInfo = `/api/get-observer-info/${observerID}`;
      try {
         const info = await axiosTokenObserver.get(linkGetInfo);
         localStorage.setItem("observer", JSON.stringify(info?.data));
         await dispatch(getInfoObserver(observerID));
      } catch (error) {
         clientError(error);
         console.log("error getNewObserverInfo : ", error);
      }
   };
}

// TODO Observer to update
// ex. update Name status dis
// ! delete Observer message === 'deleted'
// export function pusherUpdateObserver(observerID, callBack) {
// 	return async (dispatch) => {
// 		const pusher = new Pusher("ccb0403ea24a86f5024a", {
// 			cluster: "ap1",
// 		});
// 		const channel = pusher.subscribe("my-observer-" + observerID);
// 		console.log("pusherUpdateObserver : ", channel);
// 		await dispatch(setPusherChannel(channel?.name));
// 		channel.bind("event-update-observer", async (data) => {
// 			console.log("pusherUpdateObserver : ", data);
// 			if (data?.message !== "deleted") {
// 				await dispatch(getNewObserverInfo(observerID));
// 				await dispatch(getObserverResponse(data));
// 				callBack();
// 			} else {
// 				pusher.unsubscribe("my-observer-" + observerID);
// 				await dispatch(getObserverResponse(data.message));
// 				await dispatch(observerLogout(observerID));
// 			}
// 		});
// 	};
// }

// TODO device in Observer is updated someting
// ! delete device in observer message === 'deleted'
// export function pusherUpdateDeviceObserver(deviceObserver) {
// 	return async (dispatch) => {
// 		const pusher = new Pusher("ccb0403ea24a86f5024a", {
// 			cluster: "ap1",
// 		});
// 		deviceObserver?.forEach(async (device) => {
// 			const channel = pusher.subscribe(
// 				"my-observer-" + device.team_device_id
// 			);
// 			console.log("pusherUpdateDeviceObserver : ", channel);
// 			await dispatch(setPusherChannel(channel?.name));
// 			channel.bind("event-update-device-observer", async (data) => {
// 				console.log("pusherUpdateDeviceObserver : ", data);
// 				if (data?.message !== "deleted") {
// 					await dispatch(getInfoObserver(data.observer_id));
// 					await dispatch(getObserverResponse(data));
// 				} else {
// 					pusher.unsubscribe("my-observer-" + device.team_device_id);
// 					await dispatch(getInfoObserver(data.observer_id));
// 					await dispatch(getObserverResponse(data));
// 				}
// 			});
// 		});
// 	};
// }

// export function getNewObserverDevice(teamDeviceID) {
//    return async (dispatch, getState) => {
//       const linkGetNewDeviceObserver = `/api/get-device-observer/${teamDeviceID}`;
//       try {
//          const res = await axiosInstance.get(linkGetNewDeviceObserver);
//          const device = res?.data;
//          const observerInfo =
//             getState().observer?.observerInfo?.device_observer;
//          const currentTeamDeviceObserver =
//             getState().observer.observerInfo.device_observer
//                ?.team_device_observer;
//          const currentIndex = currentTeamDeviceObserver?.findIndex(
//             (device) => device.team_device_id === teamDeviceID
//          );
//          const newObserverDevice = [
//             ...currentTeamDeviceObserver?.slice(0, currentIndex),
//             device,
//             ...currentTeamDeviceObserver?.slice(currentIndex + 1),
//          ];
//          const newObserverInfo = {
//             ...observerInfo,
//             team_device_observer: newObserverDevice,
//          };
//          const finalData = { device_observer: newObserverInfo };
//          await dispatch(getInfoObserverSuccess(finalData));
//       } catch (error) {
//          console.log("error getNewObserverDevice : ", error);
//       }
//    };
// }
// TODO Login
export function observerLogin(teamCode, observerCode, password, callBack) {
   return async (dispatch) => {
      try {
         const result = await axiosInstance.post("/api/observer-login", {
            team_code: teamCode,
            observer_code: observerCode,
            password: password,
         });
         const currentObserverId = moment(result?.data?.current_observer_id).format("YYYY-MM-DD HH:mm:ss");
         await dispatch(observerLoginSuccess(result?.data?.observer));
         localStorage.setItem("observer", JSON.stringify(result?.data?.observer));
         localStorage.setItem("token_observer", result?.data?.token);
         localStorage.setItem("time_interval_observer", result?.data?.time_interval);
         localStorage.setItem("observer_accessed_at", JSON.stringify(result.data.observer_accessed_at));
         localStorage.setItem("current_observer_id", JSON.stringify(currentObserverId));
         localStorage.setItem("version", JSON.stringify(result.data.version));
         callBack();
      } catch (error) {
         console.log("observerLogin : ", error);
         await dispatch(observerLoginFailure());
         clientError(error);
         throw error;
      }
   };
}

// TODO Logout
export function observerLogout(observerId) {
   return async (dispatch) => {
      const linkGetLogoutObserver = `/api/observer-logout/${observerId}`;
      try {
         await axiosTokenObserver.get(linkGetLogoutObserver);
         localStorage.removeItem("observer");
         localStorage.removeItem("token_observer");
         localStorage.removeItem("time_interval_observer");
         localStorage.removeItem("observer_accessed_at");
         localStorage.removeItem("current_observer_id");
         localStorage.removeItem("version");
         await dispatch(observerLogoutSuccess());
         console.log("observerLogout Pass!!!!");
      } catch (error) {
         clientError(error);
         console.log("error observerLogout : ", error);
      }
   };
}

export function forceObserverLogout(code) {
   return async (dispatch) => {
      const linkGetLogoutObserver = `/api/force-logout-observer/${code}`;
      try {
         await axiosTokenObserver.get(linkGetLogoutObserver);
         localStorage.removeItem("observer");
         localStorage.removeItem("token_observer");
         localStorage.removeItem("time_interval_observer");
         localStorage.removeItem("observer_accessed_at");
         localStorage.removeItem("current_observer_id");
         localStorage.removeItem("version");
         await dispatch(observerLogoutSuccess());
         console.log("observerLogout Pass!!!!");
      } catch (error) {
         clientError(error);
         console.log("error observerLogout : ", error);
      }
   };
}

// * API
export function getInfoObserver(observerId) {
   return async (dispatch) => {
      try {
         const res = await axiosTokenObserver.get(`/api/observer/${observerId}`);
         await dispatch(getInfoObserverSuccess(res.data));
         console.log("res : ", res.data.device_observer);
      } catch (error) {
         console.log("getInfoObserver : ", error);
         await dispatch(getInfoObserverFailure());
         clientError(error);
         throw error;
      }
   };
}

// * API
export function getAllObserverNotification(observerID) {
   return async (dispatch) => {
      try {
         const linkGetAllNotification = `/api/observer/notifications/${observerID}`;
         const allNoti = await axiosTokenObserver.get(linkGetAllNotification);
         await dispatch(observerBadgeNotificationSuccess(allNoti?.data));
      } catch (error) {
         console.log("error getAllObserverNotification : ", error);
         clientError(error);
         await dispatch(observerBadgeNotificationFailure());
      }
   };
}

// * API
export function observerReadAllNotification(pageIndex, pageSize, currentData, observerID) {
   return async (dispatch, getState) => {
      try {
         const params = {
            page_index: pageIndex,
            page_size: pageSize,
            current_data: currentData,
         };
         const linkReadAllNotification = `/api/observer/notifications-read/${observerID}`;
         const allRead = await axiosTokenObserver.get(linkReadAllNotification, {
            params,
         });
         let notification = getState().observer?.observerAllNotification;
         notification = [...notification, ...allRead.data?.notification_all];
         await dispatch(observerAllNotificationSuccess(notification));
         await dispatch(setTotalNotification(allRead.data?.total_Notification));
      } catch (error) {
         console.log("error observerReadAllNotification : ", error);
         clientError(error);
         await dispatch(observerAllNotificationFailure());
      }
   };
}

// * API
export function getObserverNotification(device_id) {
   return async (dispatch) => {
      try {
         const linkGetNotification = `/api/observer/notification/${device_id}`;
         const noti = await axiosTokenObserver.get(linkGetNotification);
         await dispatch(observerNotificationSuccess(noti?.data));
      } catch (error) {
         console.log("error getObserverNotification : ", error);
         clientError(error);
         await dispatch(observerNotificationFailure());
      }
   };
}

// * API
export function observerReadNotification(device_id, pageIndex, pageSize, currentData) {
   return async (dispatch, getState) => {
      try {
         const params = {
            page_index: pageIndex,
            page_size: pageSize,
            current_data: currentData,
         };
         const linkReadNotification = `/api/observer/notification-read/${device_id}`;
         const read = await axiosTokenObserver.get(linkReadNotification, {
            params,
         });
         let notifications = getState()?.observer?.observerNotification;
         notifications = [...notifications, ...read?.data.notificationDevice];
         await dispatch(observerNotificationSuccess(notifications));
         await dispatch(setTotalNotificationDevice(read.data.totalNotificationDevice));
      } catch (error) {
         console.log("error observerReadNotification : ", error);
         clientError(error);
         await dispatch(observerNotificationFailure());
      }
   };
}

//  TODO Survey Response Notification to Observer
// export function pusherBindObserversMessage(observerID) {
// 	return async (dispatch) => {
// 		if (!observerID) {
// 			return;
// 		}
// 		const pusher = new Pusher("ccb0403ea24a86f5024a", {
// 			cluster: "ap1",
// 		});
// 		const channel = pusher.subscribe("my-channel-" + observerID);
// 		console.log("pusherBindObserversMessage : ", channel);
// 		await dispatch(setPusherChannel(channel?.name));
// 		channel.bind("observer-event", async (data) => {
// 			console.log("Responses Return Messages ::::::::: ", data);
// 			await dispatch(getObserverResponse(data));
// 			await dispatch(getAllObserverNotification(observerID));
// 		});
// 	};
// }

// TODO Device in Observer update someting return on message.
// export function pusherBindDeviceObserversMessage(observerID) {
// 	return async (dispatch) => {
// 		if (!observerID) {
// 			console.log("pusherBindDeviceObserversMessage");
// 			return;
// 		}
// 		const pusher = new Pusher("ccb0403ea24a86f5024a", {
// 			cluster: "ap1",
// 		});
// 		const channel = pusher.subscribe("my-channel-" + observerID);
// 		await dispatch(setPusherChannel(channel?.name));
// 		console.log("channel : ", channel);
// 		channel.bind("observer-event", async (data) => {
// 			await dispatch(getObserverResponse(data));
// 		});
// 	};
// }

// * API
// Delete All Notification in Device id.
export function observerNotificationsDeleteAllDevice(deviceID, observerID) {
   return async (dispatch, getState) => {
      if (!deviceID) {
         return;
      }
      try {
         const linkDeleteAllDevice = `/api/observer/notification/delete-all/${deviceID}`;
         await axiosTokenObserver.delete(linkDeleteAllDevice);
         let notification = getState().observer?.observerNotification;
         notification = notification?.filter(({ device_id }) => device_id !== deviceID);
         await dispatch(observerNotificationSuccess(notification));
         await dispatch(getAllObserverNotification(observerID));
         window.location.reload();
      } catch (error) {
         console.log("observerNotificationReadAllDevice error : ", error);
         clientError(error);
         await dispatch(observerNotificationFailure());
      }
   };
}

// * API
// delete notification from selected survey_device_log_id
export function observerNotificationDeleteDevice(logID, observerID) {
   return async (dispatch, getState) => {
      if (!logID) {
         return;
      }
      try {
         const linkDeleteNotiDevice = `/api/observer/notification/delete/${logID}`;
         await axiosTokenObserver.delete(linkDeleteNotiDevice);
         let notifications = getState().observer?.observerNotification;
         notifications = notifications?.filter(({ id }) => id !== logID);
         await dispatch(observerNotificationSuccess(notifications));
         await dispatch(getAllObserverNotification(observerID));
      } catch (error) {
         clientError(error);
         console.log("error observerNotificationDeleteDevice : ", error);
      }
   };
}

// * API
// delete allnotification from selected survey_device_log_id
export function observerAllNotificationDeleteDevice(logID, observerID) {
   return async (dispatch, getState) => {
      if (!logID) {
         return;
      }
      try {
         const linkDeleteNotiDevice = `/api/observer/notification/delete/${logID}`;
         await axiosTokenObserver.delete(linkDeleteNotiDevice);
         let notifications = getState().observer?.observerAllNotification;
         notifications = notifications.filter(({ id }) => id !== logID);
         await dispatch(observerAllNotificationSuccess(notifications));
         await dispatch(getAllObserverNotification(observerID));
      } catch (error) {
         clientError(error);
         console.log("error observerAllNotificationDeleteDevice : ", error);
      }
   };
}

//* API
// delete allNotification all Observer / Clear Notification
export function observerDeleteAllNotification(observerID) {
   return async (dispatch, getState) => {
      try {
         const linkDeleteNotificationAll = `/api/observer/notification/clear/${observerID}`;
         await axiosTokenObserver.delete(linkDeleteNotificationAll);
         let notification = getState().observer.observerAllNotification;
         notification = [];
         await dispatch(observerAllNotificationSuccess(notification));
         await dispatch(getAllObserverNotification(observerID));
         window.location.reload();
      } catch (error) {
         clientError(error);
         console.log("error observerNotificationDeleteDevice : ", error);
      }
   };
}

//* API
// Fetch Data After Survey Responses by fetching one by one from survey_device_log_id returned from pusher
// AllNotification
export function observerGetNotificationOnPusher(id) {
   return async (dispatch, getState) => {
      try {
         const linkGetNotification = `/api/observer/notification-pusher/${id}`;
         const pusher = await axiosTokenObserver.get(linkGetNotification);
         const newNoti = [pusher?.data?.notification];
         let notifications = getState().observer?.observerAllNotification;
         const checkCurrentNoti = notifications.some((n) => n.id === pusher?.data?.notification?.id);
         if (checkCurrentNoti) {
            return;
         }
         notifications = [...newNoti, ...notifications];
         await dispatch(obserNotificationOnPusherSuccess(notifications));
      } catch (error) {
         console.log("error observerGetNotificationOnPusher : ", error);
         await dispatch(obserNotificationOnPusherFailure());
         clientError(error);
      }
   };
}

//* API
// Fetch Data After Survey Responses by fetching one by one from survey_device_log_id returned from pusher
// Selected Device Notification
export function observerGetNotificationDeviceOnPusher(id) {
   return async (dispatch, getState) => {
      try {
         const linkGetNotification = `/api/observer/notification-pusher/${id}`;
         const pusher = await axiosTokenObserver.get(linkGetNotification);
         const newNoti = [pusher?.data?.notification];
         let notifications = getState().observer?.observerNotification;
         const checkCurrentNoti = notifications.some((n) => n.id === pusher?.data?.notification?.id);
         if (checkCurrentNoti) {
            return;
         }
         notifications = [...newNoti, ...notifications];
         await dispatch(obserNotificationDeviceOnPusherSuccess(notifications));
      } catch (error) {
         console.log("error observerGetNotificationOnPusher : ", error);
         await dispatch(obserNotificationDeviceOnPusherFailure());
         clientError(error);
      }
   };
}

// *API
// Read All Notifications From DeviceId
export function deviceReadNotificationsAll(device_id) {
   return async (dispatch) => {
      try {
         const linkGetReadDeviceNotificationsAll = `/api/observer/read-device-all-notifications/${device_id}`;
         await axiosTokenObserver.get(linkGetReadDeviceNotificationsAll);
         window.location.reload();
      } catch (error) {
         console.log("deviceReadNotificationsAll :: ", error);
         clientError(error);
      }
   };
}

// *API
// Read All Notifications From ObserverId
export function readNotificationsAll(observerId) {
   return async (dispatch) => {
      try {
         const linkGetReadNotificationsAll = `/api/observer/read-all-notifications/${observerId}`;
         await axiosTokenObserver.get(linkGetReadNotificationsAll);
         window.location.reload();
      } catch (error) {
         console.log("readNotificationAll ::: ", error);
         clientError(error);
      }
   };
}

//* API
// Read Device Notification One By One Using survey_device_log_id
export function readNotificationByID(id, device_id, pageIndex, pageSize, currentData, observerID) {
   return async (dispatch) => {
      try {
         const linkGetReadNotificationsAll = `/api/observer/read-byid-notification/${id}`;
         await axiosTokenObserver.get(linkGetReadNotificationsAll);
         await dispatch(getAllObserverNotification(observerID));
         await dispatch(observerNotificationFailure());
         await dispatch(observerReadNotification(device_id, pageIndex, pageSize, currentData));
      } catch (error) {
         console.log("readNotificationByID :: ", error);
         clientError(error);
      }
   };
}

//* API
// Read AllNotification One By One Using survey_device_log_id
export function readAllNotificationsByID(id, pageIndex, pageSize, currentData, observerID) {
   return async (dispatch) => {
      try {
         const linkGetReadNotificationsAll = `/api/observer/read-byid-notification/${id}`;
         await axiosTokenObserver.get(linkGetReadNotificationsAll);
         await dispatch(getAllObserverNotification(observerID));
         await dispatch(observerAllNotificationFailure());
         await dispatch(observerReadAllNotification(pageIndex, pageSize, currentData, observerID));
      } catch (error) {
         console.log("readAllNotificationsByID :: ", error);
         clientError(error);
      }
   };
}

export function getCheckDevicesOnline(observerId) {
   return async (dispatch) => {
      const currentObserver = await JSON.parse(localStorage.getItem("current_observer_id"));
      const version = await JSON.parse(localStorage.getItem("version"));
      const linkGetCheckOnline = `/api/observer/check-online`;
      try {
         const res = await axiosTokenObserver.get(linkGetCheckOnline, {
            params: {
               observer_id: observerId,
               current_observer: currentObserver,
               version: version || 0,
            },
         });
         console.log("res getCheckDevicesOnline =>>>>>> : ", res.data);
         if (res?.data?.key) {
            await dispatch(getInfoObserver(observerId));
         }
      } catch (error) {
         console.log("error getCheckDevicesOnline : ", error);
         clientError(error);
      }
   };
}

export const OBSERVER_LOGIN_SUCCESS = "OBSERVER_LOGIN_SUCCESS";
export const OBSERVER_LOGIN_FAILURE = "OBSERVER_LOGIN_FAILURE";
export const OBSERVER_LOOUT_SUCCESS = "OBSERVER_LOOUT_SUCCESS";
export const GET_INFO_OBSERVER_SUCCESS = "GET_INFO_OBSERVER_SUCCESS";
export const GET_INFO_OBSERVER_FAILURE = "GET_INFO_OBSERVER_FAILURE";
export const PUSHER_BIND_OBSERVERS_MESSAGE = "PUSHER_BIND_OBSERVERS_MESSAGE";
export const OBSERVER_NOTIFICATION_SUCCESS = "OBSERVER_NOTIFICATION_SUCCESS";
export const OBSERVER_NOTIFICATION_FAILURE = "OBSERVER_NOTIFICATION_FAILURE";
export const OBSERVER_ALL_NOTIFICATION_SUCCESS = "OBSERVER_ALL_NOTIFICATION_SUCCESS";
export const OBSERVER_ALL_NOTIFICATION_FAILURE = "OBSERVER_ALL_NOTIFICATION_FAILURE";
export const OBSERVER_BADGE_NOTIFICATION_SUCCESS = "OBSERVER_BADGE_ALL_NOTIFICATION_SUCCESS";
export const OBSERVER_BADGE_NOTIFICATION_FAILURE = "OBSERVER_BADGE_NOTIFICATION_FAILURE";
export const OBSERVER_NOTIFICATION_ON_PUSHER_SUCCESS = "OBSERVER_NOTIFICATION_ON_PUSHER_SUCCESS";
export const OBSERVER_NOTIFICATION_ON_PUSHER_FAILURE = "OBSERVER_NOTIFICATION_ON_PUSHER_FAILURE";
export const OBSERVER_NOTIFICATION_DEVICE_ON_PUSHER_SUCCESS = "OBSERVER_NOTIFICATION_DEVICE_ON_PUSHER_SUCCESS";
export const OBSERVER_NOTIFICATION_DEVICE_ON_PUSHER_FAILURE = "OBSERVER_NOTIFICATION_DEVICE_ON_PUSHER_FAILURE";
export const TOTAL_NOTIFICATION_DEVICE = "TOTAL_NOTIFICATION_DEVICE";
export const TOTAL_NOTIFICATION = "TOTAL_NOTIFICATION";

export const observerLoginSuccess = (auth) => ({
   type: OBSERVER_LOGIN_SUCCESS,
   payload: { auth },
});
export const observerLoginFailure = () => ({
   type: OBSERVER_LOGIN_FAILURE,
});

export const observerLogoutSuccess = () => ({
   type: OBSERVER_LOOUT_SUCCESS,
});

export const getInfoObserverSuccess = (info) => ({
   type: GET_INFO_OBSERVER_SUCCESS,
   payload: { info },
});
export const getInfoObserverFailure = () => ({
   type: GET_INFO_OBSERVER_FAILURE,
});

export const getObserverResponse = (obServer) => ({
   type: PUSHER_BIND_OBSERVERS_MESSAGE,
   payload: { obServer },
});

export const observerNotificationSuccess = (deviceLog) => ({
   type: OBSERVER_NOTIFICATION_SUCCESS,
   payload: { deviceLog },
});

export const observerNotificationFailure = () => ({
   type: OBSERVER_NOTIFICATION_FAILURE,
});

export const observerAllNotificationSuccess = (noti) => ({
   type: OBSERVER_ALL_NOTIFICATION_SUCCESS,
   payload: { noti },
});

export const observerAllNotificationFailure = () => ({
   type: OBSERVER_ALL_NOTIFICATION_FAILURE,
});

export const observerBadgeNotificationSuccess = (badge) => ({
   type: OBSERVER_BADGE_NOTIFICATION_SUCCESS,
   payload: { badge },
});
export const observerBadgeNotificationFailure = () => ({
   type: OBSERVER_BADGE_NOTIFICATION_FAILURE,
});

export const obserNotificationOnPusherSuccess = (pusher) => ({
   type: OBSERVER_NOTIFICATION_ON_PUSHER_SUCCESS,
   payload: { pusher },
});

export const obserNotificationOnPusherFailure = () => ({
   type: OBSERVER_NOTIFICATION_ON_PUSHER_FAILURE,
});

export const obserNotificationDeviceOnPusherSuccess = (pusherDevice) => ({
   type: OBSERVER_NOTIFICATION_DEVICE_ON_PUSHER_SUCCESS,
   payload: { pusherDevice },
});

export const obserNotificationDeviceOnPusherFailure = () => ({
   type: OBSERVER_NOTIFICATION_DEVICE_ON_PUSHER_FAILURE,
});

export const setTotalNotification = (total) => ({
   type: TOTAL_NOTIFICATION,
   payload: { total },
});

export const setTotalNotificationDevice = (total) => ({
   type: TOTAL_NOTIFICATION_DEVICE,
   payload: { total },
});
