import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
   wrapper: {
      display: 'block',
      textAlign: 'center',
      // padding: '32px 32px 0',
      width: 200,
      // minHeight: '50vh',
   },
   vector: {
      maxWidth: '100%',
      width: '100%',
      height: 'auto',
   },
   wrapperImg: {
      width: '100%',
      height: 'auto',
      margin: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
   },
   alert: {
      marginBottom: 32,
      width: '100%',
   },
}));

const ComponentLoading = ({height = null}) => {
   const classes = useStyles();
   return (
      <Box
         bgcolor={{ xs: '#fafbfc', sm: 'unset' }}
         display="flex"
         height={ height ?? "100vh"}
         justifyContent="center"
         alignItems="center"
      >
         <Box className={classes.wrapper}>
            <div className={classes.wrapperImg}>
               <img className={classes.vector} src="/images/Double-Ring-loading.svg" alt="loding survey" />
            </div>
         </Box>
      </Box>
   );
};

export default ComponentLoading;
