// import { JsonObject, FunctionFactory } from "survey-react";
import * as widgets from "surveyjs-widgets";
import * as Survey from "survey-react";

function useInitSurveyConfig() {
    
    function matrixdropdownAllRowValidator([value]) {
        const question = this.question;
        if (question.getType() === "matrixdropdown" && question.cellType === "boolean") {
            if (!value) {
                return false
            }
            const selectedRows = Object.keys(value);
            return selectedRows.length === question.rows.length;
        } else {
            return true
        }
    }
    function matrixdropdownAllCellValidator([value]) {
        // const question = this.question;
        // if (question.getType() === "matrixdropdown" && question.cellType === "boolean") {
        //     const existingRows = question.rows.map(row => row.value);
        //     const existingColumns = question.columns.map(column => column.name);
        //     if (!value || (value && Object.keys(value).length !== existingRows.length)) {
        //         return false
        //     }
        //     let selectedRowCells = {};
        //     Object.entries(value).forEach(([row, columns]) => { selectedRowCells[row] = Object.keys(columns) })
        //     return existingRows.map((row) => existingColumns.every(column => selectedRowCells[row].includes(column))).every(isExist => isExist)
        // } else {
        //     return true
        // }

        
        // cancel AllCellValidator,  use as AtleastOneValidator here

        const question = this.question;
        if (question.getType() === "matrixdropdown" && question.cellType === "boolean") {
            if (!value) {
                return false
            }
            return  Object.values(value).some(row=> Object.values(row).some(Boolean))
        } else {
            return true
        }
    }
    function matrixdropdownAtleastOneValidator([value]) {
        const question = this.question;
        if (question.getType() === "matrixdropdown" && question.cellType === "boolean") {
            if (!value) {
                return false
            }
            return  Object.values(value).some(row=> Object.values(row).some(Boolean))
        } else {
            return true
        }
    }


    Survey.JsonObject.metaData.addProperty("matrix", { name: "question_score" });
    Survey.JsonObject.metaData.addProperty("radiogroup", { name: "question_score" });
    // JsonObject.metaData.addProperty("matrixdropdown", { name: "question_score" });
    // JsonObject.metaData.addProperty("questionbase", { name: "question_score" }); // use this for addProperty to all question include html image
    // JsonObject.metaData.addProperty("question", { name: "question_score" }); // use this for addProperty to all question except html image
    // doc (https://surveyjs.io/Documentation/Survey-Creator#addproperties)

    Survey.FunctionFactory.Instance.register("matrixdropdownAllRowValidator", matrixdropdownAllRowValidator);
    Survey.FunctionFactory.Instance.register("matrixdropdownAllCellValidator", matrixdropdownAllCellValidator);
    Survey.FunctionFactory.Instance.register("matrixdropdownAtleastOneValidator", matrixdropdownAtleastOneValidator);

    widgets.inputmask(Survey);
}
const SurveyReactConfig = () => {
    useInitSurveyConfig();

    return null;
};

export default SurveyReactConfig;