import axios from "axios";
import { getConfiguration } from '../config/server';

const instance = axios.create({
  baseURL: getConfiguration().apiUrl,
   headers: {
      "X-localization": localStorage.getItem("i18nextLng") || "en",
   },
});

instance.interceptors.request.use(async (config) => {
  const jwtToken = localStorage.getItem('token_observer');
  if (jwtToken != null) {
    config.headers['Authorization'] = `Bearer ${jwtToken}`;
    config.headers['X-localization'] = localStorage.getItem("i18nextLng") || "en";
    // config.transformResponse = data => JSONBigInt.parse(data);
  }

  return config;
}, error => {
  return Promise.reject(error);
});


let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

instance.interceptors.response.use(response => {
  const data = response.data
  const originalRequest = response.config;
  // console.log('res here',response);
  
  if (data.status === "Token is Expired" && !originalRequest._retry) {
    if (isRefreshing) {
      // console.log('refreshing');
      return new Promise(function (resolve, reject) {
        failedQueue.push({ resolve, reject });
      })
        .then(token => {
          originalRequest.headers['Authorization'] = 'Bearer ' + token;
          return instance(originalRequest);
        })
        .catch(err => {
          return Promise.reject(err);
        });
    }
    originalRequest._retry = true;
    isRefreshing = true;

    return new Promise(function (resolve, reject) {
      instance
        // .post('/fooUrl/refreshToken', {refreshToken: "fooToken"})
        .post("/api/refresh/observer", null, { headers: { Authorization: "Bearer " + localStorage.token_observer } })
        .then(({ data }) => {
          localStorage.token_observer = data.access_token // change token in local
          instance.defaults.headers.common['Authorization'] = 'Bearer ' + data.access_token;
          originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
          // console.log('originalRequest1',originalRequest.data);
          // console.log('type of',typeof(originalRequest.data));
          if (typeof(originalRequest.data) === 'string') {
            originalRequest.data = JSON.parse(originalRequest.data)
          }
          // console.log('after pasrse type of',typeof(originalRequest.data));
          processQueue(null, data.access_token);
          resolve(instance(originalRequest));
        })
        .catch(err => {
          processQueue(err, null);
          reject(err);
        })
        .then(() => {
          isRefreshing = false;
        });
    });
  }
  return response;
},
  err => {

    return Promise.reject(err);
  }
);


export const axiosTokenObserver = instance;