import { combineReducers } from "redux";
import auth from "./AuthReducer";
import pusher from "./PusherReducer";
import observer from "./ObserverReducer";
import channel from "./ChannelReducer";

export default combineReducers({
   auth,
   pusher,
   observer,
   channel,
});
