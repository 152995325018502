import Pusher from "pusher-js";
const { REACT_APP_PUSHER_APP_KEY, REACT_APP_PUSHER_APP_CLUSTER } = process.env;

const createPusher = async (config = {}) => {
	Pusher.logToConsole = true;
	const { channelName, ...restConfig } = config;
	const pusher = new Pusher(REACT_APP_PUSHER_APP_KEY, {
		cluster: REACT_APP_PUSHER_APP_CLUSTER,
		...restConfig,
	});
	const channel = pusher.subscribe(channelName); 
	console.log("=============== ", pusher);
	console.log("channel ::::::::: ", channel);
	return { pusher, channel };
};
export default createPusher;
