import moment from "moment";
import { axiosTokenDevice } from "src/common/AxiosTokenDevice";
import clientError from "src/common/handleClientError";
import axiosInstance from "../../common/axiosInstance";

export function login(teamCod, deviceCod, password, callBack) {
   return async (dispatch) => {
      try {
         const result = await axiosInstance.post("/api/login", {
            team_code: teamCod,
            device_code: deviceCod,
            password: password,
         });
         const currentDeviceId = moment(result?.data?.current_device_id).format("YYYY-MM-DD HH:mm:ss");
         await dispatch(authenticationSuccess(result.data));
         localStorage.setItem("user", JSON.stringify(result.data));
         localStorage.setItem("token_device", result.data.token);
         localStorage.setItem("device_accessed_at", JSON.stringify(result.data.device_accessed_at));
         localStorage.setItem("current_device_id", JSON.stringify(currentDeviceId));
         localStorage.setItem("version", JSON.stringify(result.data.version));
         callBack();
      } catch (error) {
         console.log(error);
         await dispatch(authenticationFailure());
         clientError(error);
         throw error;
      }
   };
}

export function logout(code, deviceId, callback) {
   return async (dispatch) => {
      try {
         await axiosTokenDevice.get(`/api/logout/${code}`);
         await dispatch(authenticationFailure());
         localStorage.removeItem("user");
         localStorage.removeItem("token_device");
         localStorage.removeItem("device_accessed_at");
         localStorage.removeItem("current_device_id");
         callback();
      } catch (error) {
         clientError(error);
         console.log("error logout : ", error);
      }
   };
}

export function forceLogout(code, callback) {
   return async (dispatch) => {
      try {
         await axiosTokenDevice.get(`/api/force-logout/${code}`);
         await dispatch(authenticationFailure());
         localStorage.removeItem("user");
         localStorage.removeItem("token_device");
         localStorage.removeItem("device_accessed_at");
         localStorage.removeItem("current_device_id");
         localStorage.removeItem("version");
         callback();
      } catch (error) {
         clientError(error);
         console.log("error logout : ", error);
      }
   };
}

export const AUTHENTICATION_SUCCESS = "AUTHENTICATION_SUCCESS";
export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";

export const authenticationSuccess = (auth) => ({
   type: AUTHENTICATION_SUCCESS,
   payload: { auth },
});

export const authenticationFailure = () => ({
   type: AUTHENTICATION_FAILURE,
});
