const { NODE_ENV, REACT_APP_API_URL } = process.env;

const serverVars = {
    authUrl: '#{authUrl}#',
    apiUrl: '',
  };

  const localVars = {
    authUrl: 'local_auth_url',
    apiUrl: REACT_APP_API_URL,

  };

export function getConfiguration() {
    if (NODE_ENV === 'production') {
        return serverVars;
    }

    return localVars;
}