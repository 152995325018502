import {
  GET_DEFAULT_SCHEDULES_FAILURE,
  GET_SCHEDULES_FAILURE,
  GET_DEFAULT_SCHEDULES_SUCCESS,
  GET_MESSAGE_SUCCESS,
  GET_SCHEDULES_SUCCESS,
} from "../actions/PusherAction";

// const ex = [
//   {
//     actived_at: "2022-02-02T04:34:55.357846Z",
//     begin_date: "2022-02-02T04:31:00.000001Z",
//     content: {
//       content:
//         '<!DOCTYPE html>\n   <html lang="en">\n   <head>\n     <meta charset="UTF-8">\n     <meta http-equiv="X-UA-Compatible" content="IE=edge">\n     <meta name="viewport" content="width=device-width, initial-scale=1.0">\n     <style>* { box-sizing: border-box; } body {margin: 0;}*{box-sizing:border-box;}body{margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;}</style>\n     <title>Document</title>\n   </head>\n   <body style="height: 100vh;overflow: hidden !important;">\n     <iframe src="http://34.87.28.33:30110/d/TbuEIpR" width="100%" height="100%" data-surveyid="994200d8-c942-41dc-acbd-f40a3f7247a4" class="ifreme-survey"></iframe>\n   </body>\n   </html>',
//       content_html:
//         '<iframe src="http://34.87.28.33:30110/d/TbuEIpR" width="100%" height="100%" data-surveyid="994200d8-c942-41dc-acbd-f40a3f7247a4" class="ifreme-survey"></iframe>',
//       content_style:
//         "* { box-sizing: border-box; } body {margin: 0;}*{box-sizing:border-box;}body{margin-top:0px;margin-right:0px;margin-bottom:0px;margin-left:0px;}",
//       created_at: "2022-02-01T09:47:46.639236Z",
//       description: null,
//       group: null,
//       height: "1024px",
//       id: "c2ce8ddb-2fe9-44d2-87cd-8e6c08e542f8",
//       name: "แกล้งโง่ก็ลำบาก รู้มากก็เสียใจ V2",
//       survey_id: "994200d8-c942-41dc-acbd-f40a3f7247a4",
//       team_id: "9ede80e8-69ef-4556-9f10-88415bdc85da",
//       updated_at: "2022-02-01T09:57:15.222861Z",
//       width: "768px",
//     },
//     created_at: "2022-02-02T04:31:37.009514Z",
//     end_date: "2022-02-02T16:00:00.000001Z",
//     id: "b9955d99-f81e-4955-8b1b-565865b0b1f1",
//     is_specific_time: true,
//     ref_id: null,
//     team_device_content_id: "c2ce8ddb-2fe9-44d2-87cd-8e6c08e542f8",
//     team_device_job: "52a7924b-b37c-4b7d-82eb-b1e536d13894",
//     updated_at: "2022-02-02T04:34:55.358207Z",
//   },
// ];

const initialState = {
  message: null,
  schedules: [],
  defaultSchedules: [],
};

export default function pushReducer(state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGE_SUCCESS:
      return {
        ...state,
        message: action.payload.msg,
      };
    case GET_SCHEDULES_SUCCESS:
      return {
        ...state,
        schedules: action.payload.schedule,
      };
    case GET_DEFAULT_SCHEDULES_SUCCESS:
      return {
        ...state,
        defaultSchedules: action.payload.defaultSchedule,
      };
    case GET_SCHEDULES_FAILURE:
      return {
        ...state,
        schedules: [],
      };
    case GET_DEFAULT_SCHEDULES_FAILURE:
      return {
        ...state,
        defaultSchedules: [],
      };
    default:
      return state;
  }
}
