import {
   UPDATE_CHANNEL_PUSHER,
   UPDATE_CHANNEL_PUSHER_LOGOUT,
} from "../actions/ChannelAction";

const initialState = {
   pusherChannel: [],
};

export default function channelReducer(state = initialState, action) {
   switch (action.type) {
      case UPDATE_CHANNEL_PUSHER:
         return {
            pusherChannel: action.payload.channel,
         };
      case UPDATE_CHANNEL_PUSHER_LOGOUT:
         return {
            pusherChannel: [],
         };
      default:
         return state;
   }
}
