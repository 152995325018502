import { createTheme } from '@material-ui/core/styles';
import { red,grey } from '@material-ui/core/colors';

// import PrompWoff2 from '../../../public/fonts/Prompt-Regular.woff2';


// const Prompt = {
//   fontFamily: 'Prompt',
//   fontStyle: 'normal',
//   fontWeight: 400,
//   fontDisplay: 'swap',
//   src: "url() format('woff2')",
//   // unicodeRange: 'U+0E01-0E5B, U+200C-200D, U+25CC'
// };
// const Prompt = {
//   fontFamily: 'Prompt',
//   fontStyle: 'normal',
//   fontDisplay: 'swap',
//   fontWeight: 400,
//   src: `
//     local('Prompt'),
//     local('Prompt-Regular'),
//     url(/fonts/Prompt-Regular.woff2) format('woff2')
//   `,
// //   unicodeRange:
// //     'U+0E01-0E5B, U+200C-200D, U+25CC',
// };
// const Kanit = {
//   fontFamily: 'Kanit',
//   fontStyle: 'normal',
//   fontDisplay: 'swap', 
//   fontWeight: 400,
//   src: `
//     local('Kanit'),
//     local('Kanit-Regular'),
//     url(/fonts/Kanit-Regular.ttf) format('truetype')
//   `,
// //   unicodeRange:
// //     'U+0E01-0E5B, U+200C-200D, U+25CC',
// };

const fontFamily = [
  'Sarabun',
  'Arial',
  'Bai Jamjuree',
  'Kanit',
  'Prompt',
  'Itim'
]
// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: fontFamily.join(','),
    subtitle1: {
      fontSize: 12,
    },
    h6: {
      fontWeight: 600
    },
    body1: {
      fontWeight: 500
    }
  },
  surveyThemeFont:fontFamily.sort(),
  palette: {
    primary: {
      main: '#1589FF',
      contrastText: '#fff',
      pallid: 'rgba(21, 137, 255, 0.1)',
    },
    secondary: {
      main: '#68BD49',
      pallid: 'rgba(104, 189, 73, 0.1)',
    },
    error: {
      main: red.A400,
      contrastText: '#fff',
      pallid: 'rgba(244, 67, 54, 0.1)',
    },
    background: {
      default: '#fafbfc',
    },
    action: {
      selected: "rgba(0, 175, 203, 0.05)",
    },
    text:{
      secondary: grey[600]
    }
  },
  overrides: {
    MuiCssBaseline:{
      '@global': {
        ".ant-picker-dropdown, .ant-select-dropdown":{
          zIndex:'99999 !important'
        },
      },
    },
    MuiButton: {
      root: {
        borderRadius: "8px",
      },
      contained: {
        boxShadow: "none",
        '&:hover': {
          boxShadow: "none",
        }
      }
    },
    MuiTextField: {
      root: {
        '& .MuiOutlinedInput-root': {
          borderRadius: `8px`,
        }
      }
    },
    MuiPaper: {
      elevation1: { boxShadow: "0 2px 14px 0 rgba(0, 0, 0, 0.06)" },
      rounded: { borderRadius: "8px" }
    },
    MuiListItem: {
      root: {
        '&&:hover': {
          backgroundColor: "rgba(0, 175, 203, 0.05)",
        }
      },

    }
  }
});

export const chartDefaultColors = [
  "#4A90E2",
  "#50E3C2",
  "#F5A623",
  "#9013FE",
  "#D0021B",
  "#F8E71C",
  "#7ED321",
  "#9B9B9B",
  "#BD10E0",
  "#8B572A",
]

export default theme;