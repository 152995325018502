
export function setPusherChannel(channel) {
	return async (dispatch, getState) => {
		const currentChannel = getState()?.channel?.pusherChannel;
		const finalCheck = currentChannel?.filter((c) => c === channel);
		const newChannel = [...currentChannel, channel];
		if (finalCheck?.length <= 0) {
			await dispatch(getPusherChannel(newChannel));
		}
	};
}

export function forceClearCache() {
	return async (dispatch) => {
		await caches.keys().then((names) => {
			console.log("forceClearCache name : ", names);
			names?.forEach(async (name) => {
				await caches.delete(name);
			});
		});
		window.location.reload();
		console.log("forceClearCache ::::::");
	};
}

export const UPDATE_CHANNEL_PUSHER = "UPDATE_CHANNEL_PUSHER";
export const UPDATE_CHANNEL_PUSHER_LOGOUT = "UPDATE_CHANNEL_PUSHER_LOGOUT";

export const getPusherChannel = (channel) => ({
	type: UPDATE_CHANNEL_PUSHER,
	payload: { channel },
});

export const getPusherChannelLogout = () => ({
	type: UPDATE_CHANNEL_PUSHER_LOGOUT,
});
