import {
	OBSERVER_LOGIN_SUCCESS,
	OBSERVER_LOGIN_FAILURE,
	GET_INFO_OBSERVER_SUCCESS,
	GET_INFO_OBSERVER_FAILURE,
	PUSHER_BIND_OBSERVERS_MESSAGE,
	OBSERVER_NOTIFICATION_SUCCESS,
	OBSERVER_NOTIFICATION_FAILURE,
	OBSERVER_ALL_NOTIFICATION_SUCCESS,
	OBSERVER_ALL_NOTIFICATION_FAILURE,
	OBSERVER_BADGE_NOTIFICATION_SUCCESS,
	OBSERVER_BADGE_NOTIFICATION_FAILURE,
	OBSERVER_NOTIFICATION_ON_PUSHER_SUCCESS,
	OBSERVER_NOTIFICATION_DEVICE_ON_PUSHER_SUCCESS,
	OBSERVER_LOOUT_SUCCESS,
	TOTAL_NOTIFICATION,
	TOTAL_NOTIFICATION_DEVICE,
} from "../actions/ObserverAction";

const initialState = {
	observer: null,
	observerInfo: null,
	pusherObserver: null,
	observerNotification: [],
	observerAllNotification: [],
	totalNotificationDevice: 0,
	totalNotification: 0,
	observerBadgeNotification: [],
};

export default function authenReducer(state = initialState, action) {
	switch (action.type) {
		case OBSERVER_LOGIN_SUCCESS:
			return {
				...state,
				observer: action.payload.auth,
			};

		case OBSERVER_LOGIN_FAILURE:
			return {
				...state,
				observer: null,
			};
		case OBSERVER_LOOUT_SUCCESS:
			return {
				observer: null,
				observerInfo: null,
				pusherObserver: null,
				observerNotification: [],
				observerAllNotification: [],
				totalNotificationDevice: 0,
				totalNotification: 0,
				observerBadgeNotification: [],
			};
		case GET_INFO_OBSERVER_SUCCESS:
			return {
				...state,
				observerInfo: action.payload.info,
			};
		case GET_INFO_OBSERVER_FAILURE:
			return {
				...state,
				observerInfo: null,
			};
		case PUSHER_BIND_OBSERVERS_MESSAGE:
			return {
				...state,
				pusherObserver: action.payload.obServer,
			};
		case OBSERVER_NOTIFICATION_SUCCESS:
			return {
				...state,
				observerNotification: action.payload.deviceLog,
			};
		case OBSERVER_NOTIFICATION_FAILURE:
			return {
				...state,
				observerNotification: [],
				totalNotificationDevice: 0,
			};
		case OBSERVER_ALL_NOTIFICATION_SUCCESS:
			return {
				...state,
				observerAllNotification: action.payload.noti,
			};
		case OBSERVER_ALL_NOTIFICATION_FAILURE:
			return {
				...state,
				observerAllNotification: [],
				totalNotification: 0,
			};
		case OBSERVER_BADGE_NOTIFICATION_SUCCESS:
			return {
				...state,
				observerBadgeNotification: action.payload.badge,
			};
		case OBSERVER_BADGE_NOTIFICATION_FAILURE:
			return {
				...state,
				observerBadgeNotification: [],
			};
		case OBSERVER_NOTIFICATION_ON_PUSHER_SUCCESS:
			return {
				...state,
				observerAllNotification: action.payload.pusher,
			};
		case OBSERVER_NOTIFICATION_DEVICE_ON_PUSHER_SUCCESS:
			return {
				...state,
				observerNotification: action.payload.pusherDevice,
			};
		case TOTAL_NOTIFICATION:
			return {
				...state,
				totalNotification: action.payload.total,
			};
		case TOTAL_NOTIFICATION_DEVICE:
			return {
				...state,
				totalNotificationDevice: action.payload.total,
			};
		default:
			return state;
	}
}
