import { QuestionFactory } from "../questionfactory";
import { Serializer } from "../jsonobject";
import { Question } from "../question";
import { ImageItemValue } from "../question_imagepicker";
import { LocalizableString } from "../localizablestring";
import { settings } from "../settings";

// Use the Question class as a base class.


export class QuestionEmotionModel extends Question {
    rateValuesChangedCallback: () => void;

    constructor(name: string) {
        super(name);
        this.createItemValues("rates");
        var self = this;
        this.registerFunctionOnPropertyValueChanged("rates", function () {
            self.fireCallback(self.rateValuesChangedCallback);
        });
        this.onPropertyChanged.add(function (sender: any, options: any) {
            if (
                options.name == "rateMin" ||
                options.name == "rateMax" ||
                options.name == "rateStep"
            ) {
                self.fireCallback(self.rateValuesChangedCallback);
            }
        });

        // var locMinRateDescriptionValue = this.createLocalizableString(
        //     "minRateDescription",
        //     this,
        //     true
        // );
        // var locMaxRateDescriptionValue = this.createLocalizableString(
        //     "maxRateDescription",
        //     this,
        //     true
        // );
        // locMinRateDescriptionValue.onGetTextCallback = function (text) {
        //     return text ? text + " " : text;
        // };
        // locMaxRateDescriptionValue.onGetTextCallback = function (text) {
        //     return text ? " " + text : text;
        // };
    }
    public onSurveyLoad() {
        super.onSurveyLoad();
        this.fireCallback(this.rateValuesChangedCallback);
    }
    /**
     * The list of rate items. Every item has value and text. If text is empty, the value is rendered. The item text supports markdown. If it is empty the array is generated by using rateMin, rateMax and rateStep properties.
     * @see rateMin
     * @see rateMax
     * @see rateStep
     */
    public get rateValues(): Array<any> {
        return this.getPropertyValue("rates");
    }
    public set rateValues(val: Array<any>) {
        this.setPropertyValue("rates", val);
    }
    protected getItemValueType() {
        return "imageitemvalue";
    }
    /**
     * This property is used to generate rate values if rateValues array is empty. It is the first value in the rating. The default value is 1.
     * @see rateValues
     * @see rateMax
     * @see rateStep
     */
    public get rateMin(): number {
        return this.getPropertyValue("rateMin");
    }
    public set rateMin(val: number) {
        if (!this.isLoadingFromJson && val > this.rateMax - this.rateStep)
            val = this.rateMax - this.rateStep;
        this.setPropertyValue("rateMin", val);
    }
    /**
     * This property is used to generate rate values if rateValues array is empty. It is the last value in the rating. The default value is 5.
     * @see rateValues
     * @see rateMin
     * @see rateStep
     */
    public get rateMax(): number {
        return this.getPropertyValue("rateMax");
    }
    public set rateMax(val: number) {
        if (!this.isLoadingFromJson && val < this.rateMin + this.rateStep)
            val = this.rateMin + this.rateStep;
        this.setPropertyValue("rateMax", val);
    }
    /**
     * This property is used to generate rate values if rateValues array is empty. It is the step value. The number of rate values are (rateMax - rateMin) / rateStep. The default value is 1.
     * @see rateValues
     * @see rateMin
     * @see rateMax
     */
    public get rateStep(): number {
        return this.getPropertyValue("rateStep");
    }
    public set rateStep(val: number) {
        if (val <= 0) val = 1;
        if (!this.isLoadingFromJson && val > this.rateMax - this.rateMin)
            val = this.rateMax - this.rateMin;
        this.setPropertyValue("rateStep", val);
    }
    protected getDisplayValueCore(keysAsText: boolean, value: any): any {
        var res = ImageItemValue.getTextOrHtmlByValue(this.visibleRateValues, value);
        return !!res ? res : value;
    }

    get visibleRateValues(): ImageItemValue[] {
        if (this.rateValues.length > 0) return this.rateValues;
        var res = [];
        var value = this.rateMin;
        var step = this.rateStep;
        while (
            value <= this.rateMax &&
            res.length < settings.ratingMaximumRateValueCount
        ) {
            res.push(new ImageItemValue(value));
            value = this.correctValue(value + step, step);
        }
        return res;
    }
    private correctValue(value: number, step: number): number {
        if (!value) return value;
        if (Math.round(value) == value) return value;
        var fr = 0;
        while (Math.round(step) != step) {
            step *= 10;
            fr++;
        }
        return parseFloat(value.toFixed(fr));
    }
    public getType(): string {
        return "emotion";
    }
    protected getFirstInputElementId(): string {
        return this.inputId + "_0";
    }
    supportGoNextPageAutomatic() {
        return true;
    }
    public supportComment(): boolean {
        return true;
    }
    public supportOther(): boolean {
        return true;
    }
    public get showLabel(): boolean {
        return this.getPropertyValue("showLabel", false);
    }
    public set showLabel(newValue: boolean) {
        this.setPropertyValue("showLabel", newValue);
    }

    /**
  * The image height.
  */
    public get imageHeight(): string {
        return this.getPropertyValue("imageHeight");
    }
    public set imageHeight(val: string) {
        this.setPropertyValue("imageHeight", val);
    }
    /**
     * The image width.
     */
    public get imageWidth(): string {
        return this.getPropertyValue("imageWidth");
    }
    public set imageWidth(val: string) {
        this.setPropertyValue("imageWidth", val);
    }
    /**
     * The image fit mode.
     */
    public get imageFit(): string {
        return this.getPropertyValue("imageFit");
    }
    public set imageFit(val: string) {
        this.setPropertyValue("imageFit", val);
    }
    /**
     * The description of minimum (first) item.
     */
    // public get minRateDescription(): string {
    //     return this.getLocalizableStringText("minRateDescription");
    // }
    // public set minRateDescription(val: string) {
    //     this.setLocalizableStringText("minRateDescription", val);
    // }
    // get locMinRateDescription(): LocalizableString {
    //     return this.getLocalizableString("minRateDescription");
    // }
    // /**
    //  * The description of maximum (last) item.
    //  */
    // public get maxRateDescription(): string {
    //     return this.getLocalizableStringText("maxRateDescription");
    // }
    // public set maxRateDescription(val: string) {
    //     this.setLocalizableStringText("maxRateDescription", val);
    // }
    // get locMaxRateDescription(): LocalizableString {
    //     return this.getLocalizableString("maxRateDescription");
    // }
    protected valueToData(val: any): any {
        if (this.rateValues.length > 0) {
            var item = ImageItemValue.getItemByValue(this.rateValues, val);
            return !!item ? item.value : val;
        }
        return !isNaN(val) ? parseFloat(val) : val;
    }
}
Serializer.addClass(
    "imageitemvalue",
    [],
    (value: any) => new ImageItemValue(value),
    "itemvalue"
);
Serializer.addProperty("imageitemvalue", {
    name: "imageLink",
    serializationProperty: "locImageLink",
});


Serializer.addClass(
    "emotion",
    [
        { name: "hasComment:switch", layout: "row" },
        {
            name: "commentText",
            dependsOn: "hasComment",
            visibleIf: function (obj: any) {
                return obj.hasComment;
            },
            serializationProperty: "locCommentText",
            layout: "row",
        },
        {
            name: "rateValues:imageitemvalue[]",
        },
        { name: "rateMin:number"},
        { name: "rateMax:number"},
        { name: "rateStep:number" },
        {
            name: "minRateDescription",
            alternativeName: "mininumRateDescription",
            serializationProperty: "locMinRateDescription",
        },
        {
            name: "maxRateDescription",
            alternativeName: "maximumRateDescription",
            serializationProperty: "locMaxRateDescription",
        },
        { name: "imageHeight:number", default: 150, minValue: 0 },
        { name: "imageWidth:number", default: 200, minValue: 0 },
        {
            name: "imageFit",
            default: "contain",
            choices: ["none", "contain", "cover", "fill"],
        },
    ],
    function () {
        return new QuestionEmotionModel("");
    },
    "question"
);

//Register the class as a question. Survey editor will know it is a question and it will show it in question toolbox.
QuestionFactory.Instance.registerQuestion("emotion", (name) => {
    return new QuestionEmotionModel(name);
});

