const { NODE_ENV, REACT_APP_API_URL, REACT_APP_API_PUBLIC } = process.env;

export function getConfigurationApiPusher() {
	if (NODE_ENV === "production") {
		// return REACT_APP_API_PUBLIC + "/api/broadcasting/auth";
		return REACT_APP_API_PUBLIC + "/api/auth/pusher";
	}
	// return REACT_APP_API_URL + "/api/broadcasting/auth";
	return REACT_APP_API_URL + "/api/auth/pusher";
}
